<template>
    <main ref="resumen" id="resumen">
        <div class="card mt-2 shadow-none" v-if="isLoading">
            <div class="card-body">
                <content-placeholders :rounded="true">
                    <content-placeholders-heading :img="false" />
                    <content-placeholders-text :lines="3" />
                </content-placeholders>
            </div>
        </div>
        <div v-else>
            <div class="py-2">
                <motivoConsultaShow :idHistoria="idHistoria" :data="resumen.motivo_consulta"></motivoConsultaShow>
            </div>
            <div class="py-2">
                <examenFisicoShow :idHistoria="idHistoria" :examenFisico="resumen.examen_fisico"></examenFisicoShow>
            </div>
            <div class="py-2">
                <exploracionFisicaShow :idHistoria="idHistoria" :data="resumen.exploracion_fisica">
                </exploracionFisicaShow>
            </div>
            <div class="py-2" v-if="resumen && resumen.evento_violencia_sexual">
                <showEventoVs :idHistoria="idHistoria" :data="resumen.evento_violencia_sexual"></showEventoVs>
            </div>
            <div class="py-2" v-if="resumen && resumen.examen_genital_anal">
                <showExamenGenitalAnal :idHistoria="idHistoria" :data="resumen.examen_genital_anal"></showExamenGenitalAnal>
            </div>
            <div class="py-2" v-if="resumen && resumen.riesgo_revictimizacion">
                <riesgoRevictimizacionShow :idHistoria="idHistoria" :data="resumen.riesgo_revictimizacion"></riesgoRevictimizacionShow>
            </div>
            <!-- <div class="py-2"><revision-sistemas-show :id-historia="idHistoria"></revision-sistemas-show></div> -->

            <div class="py-2" v-if="resumen && resumen.impresion_diagnostica">
                <impresionDxShow :idHistoria="idHistoria" :data="resumen.impresion_diagnostica"></impresionDxShow>
            </div>
            <div class="py-2" v-if="resumen && resumen.ordenes">
                <ordenClinicaShow :idHistoria="idHistoria" :data="resumen.ordenes"></ordenClinicaShow>
            </div>
            <div class="py-2" v-if="resumen && resumen.formulas">
                <formulaMedicaShow :idHistoria="idHistoria" :data="resumen.formulas"></formulaMedicaShow>
            </div>
            <div class="py-2" v-if="resumen && resumen.intervenciones">
                <intervencionesShow :idHistoria="idHistoria" :data="resumen.intervenciones"></intervencionesShow>
            </div>
            <div class="py-2" v-if="resumen && resumen.recomendaciones">
                <recomendacionesShow :idHistoria="idHistoria" :data="resumen.recomendaciones"></recomendacionesShow>
            </div>
        </div>
    </main>
</template>

<script>
import motivoConsultaShow from "../base/motivoConsulta/motivoConsultaShow";
import examenFisicoShow from "../base/examenFisico/examenFisicoShow";
import impresionDxShow from "../base/impresionDX/impresionDxShow";
import exploracionFisicaShow from "../base/examenFisico/exploracionFisicaShow";
import historiaClinicaService from "../../../services/historiaClinicaService";
import intervencionesShow from "../base/intervenciones/intervencionesShow";
import recomendacionesShow from "../base/recomendaciones/recomendacionesShow";
import showExamenGenitalAnal from "../base/baseExamenGenitalAnal/show";
import showEventoVs from "../base/baseEventosVs/show";
import riesgoRevictimizacionShow from "../base/baseRiesgoRevictimizacion/show";
import ordenClinicaShow from "../base/orden/ordenClinicaShow";
import formulaMedicaShow from "../base/formulaMedica/formulaMedicaShow";

export default {
    props: ["idHistoria", "idUsuario"],
    components: {
        motivoConsultaShow,
        examenFisicoShow,
        intervencionesShow,
        recomendacionesShow,
        impresionDxShow,
        exploracionFisicaShow,
        showExamenGenitalAnal,
        showEventoVs,
        riesgoRevictimizacionShow,
        ordenClinicaShow,
        formulaMedicaShow,
    },
    data() {
        return {
            resumen: {},
            isLoading: true,
        };
    },

    async created() {
        this.isLoading = true;
        const response = await historiaClinicaService.resumenHc(this.idHistoria);
        this.resumen = response.data;
        this.isLoading = false;
    },
};
</script>

<style scoped>
.resumen-container {
    height: 300px;
    overflow-y: scroll;
}
</style>
