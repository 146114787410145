<template>
    <div class="d-flex align-items-center justify-content-between">
        <div class="form-group">
            <label class="form-label small text-uppercase font-weight-bolder">{{ label }}</label>
            <div>
                <texto v-if="type === 'TEXT'" v-model="observacion" :class="classObject" class="text-uppercase"></texto>
                <numerico v-if="type === 'NUM'" v-model="observacion" :class="classObject"></numerico>
                <date v-if="type === 'DATE'" v-model="observacion" :class="classObject"></date>
                <opciones :antecedente="antecedente" v-if="type === 'OPC'" v-model="observacion" :class="classObject">
                </opciones>
                <multiple-options :antecedente="antecedente" v-if="type === 'MULTIPLE'" v-model="observacion" :class="classObject">
                </multiple-options>
            </div>
        </div>
    </div>
</template>
<script>
import texto from "./tipos/texto";
import numerico from "./tipos/numerico";
import opciones from "./tipos/opciones";
import date from "./tipos/date";
import multipleOptions from "./tipos/multipleOptions";
export default {
    components: {
        texto,
        numerico,
        opciones,
        date,
        multipleOptions
    },
    props: ['label', 'value', "validator", "disabled", "type", "antecedente"],
    data() {
        return {
            observacion: this.value,
        }
    },
    methods: {
        getButtonClasses(tipo) {
            return {
                'btn': true,
                'btn-outline-success': tipo !== this.tipoSeleccionado && tipo.name === 'SI',
                'btn-outline-danger': tipo !== this.tipoSeleccionado && tipo.name === 'NO',
                'btn-success': tipo === this.tipoSeleccionado,
                'btn-danger': tipo === this.tipoSeleccionado && tipo.name === 'NO',
                'btn-xs': true,
                'mx-1': true
            };
        },
    },
    watch: {
        value: async function (value) {

            if (value === "" || value === undefined || value === null) {
                this.observacion = "";
                return;
            }

            this.observacion = value;
        },
        observacion: function (value) {
            this.$emit('input', value);
        }
    },
    computed: {
        classObject: function () {
            if (this.validator === undefined || this.validator === null) {
                return "";
            }
            
            return this.validator.$error && this.validator.$dirty ? "is-invalid" : "is-valid";
        },
    },
    created() {
        this.observacion = this.value;
    },
}
</script>