<template>
    <main>
        <textarea v-model="val" class="form-control" :class="classObject" rows="5"></textarea>
        <button @click="selectOption(i)" 
            v-for="(opcion, i) in antecedente.opciones" 
            :data-tipo="`tipo_${opcion.id}`" 
            :key="`${opcion.id}_tipo`"
            :class="{'btn-light': isSelected(opcion.valor), 'btn-transparent': !isSelected(opcion.valor)}"
            class="btn btn-sm  mr-1 mt-2 shadow-sm text-bold"
            >
            <i class="fa fa-circle fa-fw fa-1x fa-fw"></i>{{ opcion.valor }}
        </button>
    </main>
</template>

<script>
export default {
    props: ['antecedente', 'value', 'classObject'],
    data() {
        return {
            val: this.value,
        };
    },
    methods: {
        selectOption(i) {
            const tipo = this.antecedente.opciones[i].valor;
            const valArray = this.val.split(', ').filter(Boolean); // Convertir en array y eliminar espacios en blanco

            if (valArray.includes(tipo)) {
                // Si el valor ya existe, eliminarlo
                this.val = valArray.filter(v => v !== tipo).join(', ');
            } else {
                // Si el valor no existe, agregarlo
                this.val = valArray.length ? `${this.val}, ${tipo}` : tipo;
            }
        },
        isSelected(valor) {
            const valArray = this.val.split(', ').filter(Boolean);
            return valArray.includes(valor);
        }
    },
    watch: {
        val: function () {
            this.$emit('input', this.val);
        },
        value: function (newValue) {
            if (newValue !== this.val) {
                this.val = newValue;
            }
        },
    },
};
</script>
